<template>
  <aside
    id="aside"
    class="menu is-unselectable"
    :class="{ 'is-invisible': !sidebar }"
  >
    <div class="menu-1">
      <ul>
        <li>
          <router-link to="/usuarios" class="menu-item">
            <label>Usuarios</label>
          </router-link>
        </li>
        <li>
          <router-link to="/vacantes" class="menu-item">
            <label>Empleos</label>
          </router-link>
        </li>
        <li>
          <router-link to="/postulantes" class="menu-item">
            <label>Demandantes</label>
          </router-link>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Sidebar',
  computed: {
    ...mapState({
      sidebar: state => state.sidebar
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.menu-1 {
    background: #0019A8;
    min-width: 230px;
    max-width: 230px;
    transition: all .15s ease-out;
  }
  .is-small-menu {
    min-width: 70px;
    max-width: 70px;
    transition: all .15s ease-out;
  }
  aside {
    display: flex;
    background-color: #0019A8;
    height: 100%;
    min-width: 230px;
    max-width: 230px;
    overflow-x: hidden;
    overflow-y: auto;
    border: solid 1px #0019A8;
    background-image: -webkit-gradient(linear,left top,left bottom,from(rgba(36,59,83,0)),to(rgba(7,12,17,.48)));
    background-image: linear-gradient(180deg ,rgba(36,59,83,0),rgba(7,12,17,.48));

  }

  aside.is-invisible {
    display: none;
  }

  .logo {
    width: 150px
  }
  .logo-mini {
    max-width: 40px;
  }

  .menu-list a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
  }

  a {
    width: 100%;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
  }

  .icon_product_flow {
    padding: 0 0.5rem;
    font-size: 20px;
  }

  .menu-item {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: left;
    padding: 0.75rem 0.5rem;

     label {
       cursor: pointer;
       margin-left: 1rem;
     }
  }
  li {
    &:hover {
    .menu-item {
      width: 100%;
      border-radius: 0 5px 5px 0;
      background-color: #bbc1cb;
    }
  }
  }
  li a i.material-icons {
    font-size: 20px;
  }

  .menu-list a i:first-child {
    padding-right: .5rem;
  }

  .menu-list a i:last-child {
    padding-left: .5rem;
  }

  .menu-list li ul {
    border-left: none;
    margin: 0;
    width: 100%;
    padding-left: 0;
  }

  .menu-list a.is-active {
    background-color: transparent;
  }

  .subtitle_menu {
    padding-left: 10px;
    padding-top: 15px;
    color: white;
    letter-spacing: .1em;
    text-transform: uppercase;
    font-size: 12px;
  }
  .router-link-exact-active {
    width: 100%;
    background-color: #bbc1cb;
  }
</style>
